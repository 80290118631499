// beaker/src/context/ThemeContext.tsx
import React, { createContext, useContext, ReactNode } from 'react';
import colors from 'tailwindcss/colors';

type Theme = 'default' | 'danger';

interface ThemeContextType {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

// Define a general type for the color shades
type ColorShades = {
  [key: string]: string;
};

const themes: Record<Theme, { primary: ColorShades; secondary: ColorShades }> =
  {
    default: {
      primary: colors.blue,
      secondary: colors.slate,
    },
    danger: {
      primary: colors.red,
      secondary: colors.slate,
    },
  };

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = React.useState<Theme>('default');

  React.useEffect(() => {
    const root = document.documentElement;
    const themeColors = themes[theme];

    // Set CSS variables for primary colors
    Object.entries(themeColors.primary).forEach(([shade, colorValue]) => {
      root.style.setProperty(`--color-primary-${shade}`, colorValue);
    });

    // Set CSS variables for secondary colors
    Object.entries(themeColors.secondary).forEach(([shade, colorValue]) => {
      root.style.setProperty(`--color-secondary-${shade}`, colorValue);
    });
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
