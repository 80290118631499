import React, { useState, useEffect, useRef } from 'react';
import { FaKey, FaArrowRight, FaTrashAlt } from 'react-icons/fa';
import { useSearchParams, useNavigate } from 'react-router-dom';

// Extracted BackgroundAnimations component
const BackgroundAnimations: React.FC = React.memo(() => {
  return (
    <>
      {/* Dynamic grid background */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {/* Shifting grid layers */}
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className="absolute inset-0 grid grid-cols-12 grid-rows-12 animate-grid-shift"
            style={{
              animationDelay: `${index * -10}s`,
              opacity: 0.15,
            }}
          >
            {[...Array(144)].map((_, i) => (
              <div key={i} className="border-[0.5px] border-teal-300"></div>
            ))}
          </div>
        ))}

        {/* Drifting, glowing particles */}
        {[...Array(30)].map((_, i) => (
          <div
            key={i}
            className="absolute rounded-full bg-teal-400 animate-particle-drift"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: '4px',
              height: '4px',
              animationDuration: `${Math.random() * 20 + 10}s`,
              animationDelay: `-${Math.random() * 20}s`,
            }}
          >
            <div className="absolute inset-0 rounded-full bg-teal-300 animate-particle-glow"></div>
          </div>
        ))}
      </div>

      {/* Gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-950 via-blue-800 to-teal-800 opacity-30"></div>
    </>
  );
});

BackgroundAnimations.displayName = 'BackgroundAnimations';

const LabSelectionPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [labCode, setLabCode] = useState('');
  const [labCodeHistory, setLabCodeHistory] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const storedHistory = localStorage.getItem('labCodeHistory');
    if (storedHistory) {
      setLabCodeHistory(JSON.parse(storedHistory));
    }
  }, []);

  const handleSubmit = (
    event?: React.FormEvent<HTMLFormElement>,
    codeParam?: string,
  ) => {
    if (event) event.preventDefault();

    const codeToUse = codeParam || labCode;

    if (!codeToUse) {
      // Handle error
      return;
    }

    // Update lab code history
    const updatedHistory = [
      codeToUse,
      ...labCodeHistory.filter((code) => code !== codeToUse),
    ];

    setLabCodeHistory(updatedHistory);

    // Save to local storage
    localStorage.setItem('labCodeHistory', JSON.stringify(updatedHistory));

    // Redirect
    navigate(`/lab/${codeToUse}`);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabCode(event.target.value);
    setShowDropdown(true);
  };

  const handleCodeClick = (code: string) => {
    setLabCode(code);
    setShowDropdown(false);
    handleSubmit(undefined, code);
  };

  const handleRemoveCode = (
    code: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();
    const updatedHistory = labCodeHistory.filter((c) => c !== code);
    setLabCodeHistory(updatedHistory);
    localStorage.setItem('labCodeHistory', JSON.stringify(updatedHistory));
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredLabCodes = labCodeHistory
    .filter((code) => code.toLowerCase().includes(labCode.toLowerCase()))
    .slice(0, 4);

  return (
    <div className="flex flex-col items-center justify-center min-h-[calc(100vh-4rem)] bg-gradient-to-br from-blue-950 via-blue-800 to-teal-800 text-white p-4 relative overflow-hidden">
      {/* Include the memoized BackgroundAnimations component */}
      <BackgroundAnimations />

      <div className="w-full max-w-md relative z-10">
        <div className="bg-primary-900 bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-3xl p-8 shadow-2xl border border-teal-400 border-opacity-30">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-1">
              <div className="relative">
                <FaKey className="absolute left-4 top-1/2 transform -translate-y-1/2 text-teal-300 text-xl" />
                <input
                  id="lab_code_input"
                  type="text"
                  name="labId"
                  value={labCode}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  className="w-full py-3 px-12 bg-primary-800 bg-opacity-75 rounded-xl border-2 border-teal-400 focus:border-teal-300 outline-none text-xl placeholder-teal-200 text-white"
                  placeholder="Enter Lab Code"
                  spellCheck="false"
                  autoComplete="off"
                  autoFocus={true}
                  required={true}
                />
              </div>
              {showDropdown && filteredLabCodes.length > 0 && (
                <div
                  ref={dropdownRef}
                  className="w-full bg-primary-800 bg-opacity-90 border border-teal-400 rounded-xl max-h-48 overflow-y-auto z-20"
                >
                  {filteredLabCodes.map((code) => (
                    <div
                      key={code}
                      onClick={() => handleCodeClick(code)}
                      className="flex justify-between items-center px-4 py-2 hover:bg-primary-700 cursor-pointer"
                    >
                      <span className="text-white">{code}</span>
                      <button
                        onClick={(e) => handleRemoveCode(code, e)}
                        className="text-red-400 hover:text-red-300"
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <button
              type="submit"
              className="w-full bg-teal-600 hover:bg-teal-500 text-white font-bold py-3 px-6 rounded-xl text-xl transition duration-300 ease-in-out flex items-center justify-center group"
            >
              Start Lab
              <FaArrowRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
            </button>
          </form>
          {searchParams.get('error') && (
            <p className="text-red-300 mt-4 text-center font-medium">
              {searchParams.get('error')}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LabSelectionPage;
